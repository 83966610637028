import React from 'react';
import { graphql } from 'gatsby';

import { paragraphToComponent } from 'providers/paragraphs/paragraphs';

import PageLayout from 'components/layouts/page/page.layout';

import PageSection from 'ui-kit/page-section/page-section';

const LandingPageProvider = ({ data }: { data: GatsbyTypes.LandingPageDataQuery }) => {
    const paragraphs = data.nodeLandingPage?.relationships?.field_landing_page_content;
    const metaData = data.nodeLandingPage?.field_meta_tags || {};

    if (!metaData.nodeTitle && data.nodeLandingPage.title) {
        metaData.nodeTitle = data.nodeLandingPage?.title;
    }

    return (
        <PageLayout metaData={metaData}>
            {paragraphs?.map((paragraph, index) => (
                <PageSection key={`page_section_${index}`}>
                    {paragraphToComponent(paragraph?.internal.type, paragraph, index)}
                </PageSection>
            ))}
        </PageLayout>
    );
};

export default LandingPageProvider;

export const query = graphql`
    query LandingPageData($nid: Int) {
        nodeLandingPage(drupal_internal__nid: { eq: $nid }) {
            drupal_internal__nid
            title
            field_meta_tags {
                title
                description
                abstract
                keywords
            }
            relationships {
                field_landing_page_content {
                    ...supportedParagraphs
                }
            }
        }
    }
`;
